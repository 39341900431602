export default {
  siteTitle: 'Get bonus',
  blogerName: 'JOGADOR',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@JJBRClips',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/lucascauduro/',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/jogajogador',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c08b5ca37',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c34181565',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-blrs.com/c31d06537',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/cdad9bc42',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c36dd6790',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cfa6cc57e',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c72f3cab9',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/cce1975fb',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c707fc72a',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/ccb269f1a',
      gameTitle: 'Doors Of Fresh (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>JOGADOR</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'JOGADOR',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  counter: true,
  counterDomainType: 'Landing Jogador BR',
  popupAd: false,
  bonus100: 'Bônus 100%',
  copyMessage: 'Código promocional copiado',
  theme: 'brazil'
};
